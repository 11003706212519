<template>
  <div class="">
    <!-- <div class="flexrow tiecg">
      <div class="tilelft"></div>
      <div class="tieright">
        <el-button type="primary" @click="goback">返回</el-button>
      </div>
    </div> -->
    <div class="" style="margin: 0 auto">
      <!-- <div class="title">
            基本信息
        </div> -->
      <div class="flexaround">
        <div>
          <div>用户名:</div>
          <div class="centerstyle toinput" style="">
            <div style="width: 300px">
              <el-input v-model="form.name" disabled></el-input>
            </div>
          </div>
          <div>账号：</div>
          <div class="centerstyle toinput">
            <div style="width: 300px">
              <el-input v-model="form.username" disabled></el-input>
            </div>
          </div>
          <div>联系方式：</div>
          <div class="centerstyle toinput">
            <div style="width: 300px">
              <el-input
                v-model="form.telephone"
                placeholder=""
                disabled
              ></el-input>
            </div>
          </div>

          <div>邮箱：</div>
          <div class="centerstyle toinput">
            <div style="width: 300px">
              <el-input v-model="form.email" placeholder="" disabled></el-input>
            </div>
          </div>
        </div>
        <div class="">
          <div>密码：</div>
          <div class="centerstyle toinput">
            <div >
              
              <div v-if="form.roles[0] == '普通用户'" style="width: 300px" class="centerstyle">
                <el-input
                  v-model="form.password"
                  type="password"
                  disabled
                ></el-input>
                <div style="margin-left: 15px">
                  <el-button type="primary" @click="resetPWD()"
                    >重置密码</el-button
                  >
                </div>
              </div>
              <div v-else>
                <div style="width: 300px">
                  <el-input
                    v-model="form.password"
                    type="password"
                    disabled
                  ></el-input>
                </div>
                <div style="font-size: 12px; color: #c4c4c4">
                  （管理员密码修改由本人点击导航-个人资料进行修改）
                </div>
              </div>
            </div>
          </div>
          <div>性别：</div>
          <div class="centerstyle toinput">
            <div style="width: 300px">
              <el-radio-group v-model="form.sex" disabled>
                <el-radio :label="1">男</el-radio>
                <el-radio :label="2">女</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div>生日：</div>
          <div class="centerstyle toinput">
            <div style="width: 300px">
              <el-date-picker
                v-model="form.date"
                type="date"
                placeholder="选择日期"
                readonly
              >
              </el-date-picker>
            </div>
          </div>

          <div>账号权限：</div>
          <div class="centerstyle toinput">
            <div style="width: 300px">
              <el-input v-model="form.roles[0]" disabled></el-input>
            </div>
          </div>
        </div>
        <div>
          <div style="width: 230px; height: 230px">
            <el-avatar
              shape="square"
              :size="230"
              :fit="fit"
              src="https://api.gbservice.cn/storage/uploads/699ab4d283acc595369836beb8082607.png"
            ></el-avatar>
          </div>
          <!-- <div style="margin-top: 20px">
            <el-button type="primary" plain>更换头像</el-button>
          </div> -->
        </div>
      </div>
      <div
        class="flexaround"
        style="
          border-top: 1px solid #dbe9ef;
          padding-top: 50px;
          margin-top: 20px;
        "
      >
        <div>
          <div>企业名称：</div>
          <div class="centerstyle toinput" style="">
            <div style="width: 300px">
              <el-input v-model="form.qyname" disabled></el-input>
            </div>
          </div>
        </div>
        <div class="">
          <div>企业地址：</div>
          <div class="centerstyle toinput">
            <div style="width: 300px" class="centerstyle">
              <el-input v-model="form.dizhi" disabled></el-input>
            </div>
          </div>
        </div>
        <div style="width: 15%"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { getUserInfo, resetPWD } from "../../../../api/user";
// getChildApi addChildApi,
export default {
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      form: {
        id: "",
        name: "",
        openid: "",
        password: "",
        photo: "",
        right_company: "",
        roles: "",
        telephone: "",
        token: "",
        sex: 1,
        squareUrl:
          "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      },
      page: 1,
      limit: 10,
      totalPage: 0,
      totalCount: 0,
      token: "",
      id: "",
      password: "",
      fit:""
    };
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
    //获取此用户token和id
    getUserInfo() {
      // debugger
      this.id = this.$route.query.id;
      this.token = this.$route.query.token;
      // console.log(this.id);
      getUserInfo({ token: this.token, id: this.id }).then((res) => {
        // console.log(res, '打印用户详情')
        // this.form = Object.assign(res.data);
        this.form.id = res.data.id
        this.form.name = res.data.name
        this.form.openid = res.data.openid
        this.form.password = res.data.password
        this.form.photo = res.data.photo
        this.form.right_company = res.data.right_company
        this.form.roles = res.data.role_type_name === undefined?'':res.data.role_type_name
        this.form.telephone = res.data.telephone
        this.form.token = this.token
        this.form.sex = this.sex
        this.form.squareUrl = this.squareUrl
      });
    },
    resetPWD() {
      let data = {
        id: this.id,
        token: this.token,
      };
      // console.log(data);
      this.$confirm("是否需要重置密码？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          resetPWD(data).then((res) => {
            console.log(res);
          });
          this.$message({
            type: "success",
            message: "密码已重置!",
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
/deep/ .el-dialog__headerbtn .el-dialog__close {
  color: #ffffff;
}

/deep/ .el-dialog__title {
  color: #ffffff;
}
/deep/.el-date-editor.el-input {
  width: 100%;
}

/deep/ .el-dialog {
  border-radius: 10px;
}
/deep/.el-button--primary.is-plain {
  width: 100%;
}

/deep/ .el-dialog__header {
  background-color: #3086fb;
  font-weight: bold;
  border-radius: 5px 5px 0 0;
}
.toinput {
  margin-bottom: 30px;
  margin-top: 15px;
}
.coianter {
  width: 100%;
  height: 800px;
  background-color: white;
  box-shadow: 3px 3px 7px 1px rgb(176 176 176 / 35%);
}

.title {
  padding: 40px;
  width: 120px;
  height: 60px;
  font-size: 24px;
  font-weight: bold;
}

.borderstyle {
  border: 1px solid;
}
.flexstyle {
  display: flex;
  flex-direction: row;
}
.flexaround {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.centerstyle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.xzbtn {
  display: flex;
  flex-direction: row-reverse;
  margin: 10px 0;
}

.bcbtn {
  width: 100%;
  /* text-align: bottom; */
  /* margin-top: 140px; */
}
.flexrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px;
}
.tilelft {
  font-size: 22px;
  font-weight: bold;
  color: #1a1a1a;
}
</style>
