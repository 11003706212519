import axios from 'axios'
import { Message } from 'element-ui' 
import router from '../router/index'
import Vue from 'vue'

// create an axios instance
const service = axios.create({
  baseURL: 'https://api.gbservice.cn/', 
  // baseURL: 'http://127.0.0.1:8000/', 
  // withCredentials: true, 
  timeout: 5000 
})

let loadingInstance = ''
let requestNum = 0;

// response interceptor
service.interceptors.response.use(

  response => {
    //文件下载请求头处理
    const headers = response.headers
    if (headers['content-type'] === 'application/zip') {
      return response.data
    }
    requestNum--;
    if (requestNum == 0) {
      loadingInstance.close();
    }
    // console.log(response.data, '返回的数据地方系统')
    const res = response.data
    if (response.request.responseURL != undefined && response.request.responseURL != '' && response.request.responseURL.indexOf("export") != -1) {
      return response;
    }

    Vue.prototype.code = res.code;

    if (res.code != 200) {
      if (res.code == 500) {
        Message({
          message: '系统错误，请联系管理员',
          type: 'error',
          duration: 5 * 1000
        })
        router.push({ path: '/login' })
      } else if (res.code == 401) {
        //返回登录
        Message({
          message: res.message,
          type: 'error',
          duration: 5 * 1000
        })
        router.push({ path: '/login' })
      } else {
        Message({
          message: res.message || res.mseeage || 'Error',
          type: 'error',
          duration: 5 * 1000
        })
      }
      return Promise.reject(new Error(res.message || res.mseeage || 'Error'))
    } else {
      return res
    }
  },
  error => {
    // console.log('err' + error) // for debug
    requestNum--;
    if (requestNum == 0) {
      loadingInstance.close();
    }
    Message({
      message: '请检查网络',
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
