import request from "../utils/request";

export function login(data) {
    return request({
        url: '/api/public/login',
        method: 'post',
        data
    })
}


export function getStaff(data) {
    return request({
        url: '/api/energy/staff',
        method: 'get',
        params: data
    })
}

//普通用户注册
export function register_putong(data) {
    return request({
        url: '/api/public/nregister',
        method: 'post',
        data
    })
}

export function settingPass(data) {
    return request({
        url: '/api/settingPass',
        method: 'post',
        data
    })
}


//获取用户信息
export function getUserInfo(data) {
    return request({
        url: '/api/public/userInfo',
        method: 'get',
        params: data
    })
}


//修改用户信息
export function updateUserInfo(data) {
    return request({
        url: '/api/user/edit',
        method: 'post',
        data
    })
}

//获取所有用户列表
export function getUserList(data) {
    return request({
        url: '/api/user/list',
        method: 'get',
        params: data
    })
}

//重置用户密码
export function resetPWD(data) {
    return request({
        url: '/api/energy/reset',
        method: 'get',
        params: data
    })
}

//获取用户登录记录
export function getUserlog(data) {
    return request({
        url: '/api/user/login/list',
        method: 'get',
        params: data
    })
}
//获取权限信息
export function roleList(data) {
    return request({
        url: '/api/user/role_list',
        method: 'get',
        params: data
    })
}
//新增管理员
export function addUser(data) {
    return request({
        url: '/api/user/ustore',
        method: 'post',
        data
    })
}
//编辑管理员
export function editUser(data) {
    return request({
        url: '/api/user/uEdit',
        method: 'post',
        data
    })
}
//权限查看
export function roleType(data) {
    return request({
        url: '/api/user/userRoleType',
        method: 'get',
        params: data
    })
}

//修改用户
export function userInfoEdit(data) {
    return request({
        url: '/api/user/userInfoEdit',
        method: 'post',
        data
    })
}

//查询账号安全信息
export function logData(data) {
    return request({
        url: '/api/permission/log',
        method: 'get',
        params: data
    })
}

//企业查询帐号信息
export function entInfo(data) {
    return request({
        url: '/api/public/info',
        method: 'get',
        params: data
    })
}

//重置密码
export function resetPass(data) {
    return request({
        url: '/api/organization/resetPass',
        method: 'post',
        data: data
    })
}

//忘记密码申请
export function userRegisterAdd(data) {
    return request({
        url: '/api/user_register/userRegisterAdd',
        method: 'post',
        data: data
    })
}

//忘记密码申请列表
export function userRegisterList(data) {
    return request({
        url: '/api/user_register/userRegisterList',
        method: 'get',
        params: data
    })
}

//申请密码重置
export function userRegisterEdit(data) {
    return request({
        url: '/api/user_register/userRegisterEdit',
        method: 'post',
        data: data
    })
}
